<template>
  <v-app>
    <div class="m-auto w-1/4">
      <h1 class="text-h4 font-weight-thin mb-4">
          <v-row align="center" justify="center">
            <v-col class="text-center -mt-20" cols="12">
              <h1 class="text-h4 font-bold mb-4 text-white" >LOGIN</h1>
              <div class="w-full">
                <v-row>
                  <v-col cols="12">
                    <v-form @submit.prevent="login">
                      <v-text-field
                        v-model="username"
                        label="Username"
                        outlined
                        filled
                        dark
                        ref="username"
                      ></v-text-field>

                      <!-- Password Field -->
                      <v-text-field
                        v-model="password"
                        label="Password"
                        type="password"
                        outlined
                        filled
                        dark
                        ref="password"
                      ></v-text-field>

                      <v-card-actions class="">
                        <v-row>
                          <!-- <v-col>
                            <v-btn
                              @click="openForgot"
                              text
                              dark
                              dense
                              elevation="0"
                              :disabled="loading"
                            >Forgot Password</v-btn>
                          </v-col> -->
                          <v-spacer></v-spacer>
                          <v-col>
                            <v-btn
                              elevation="0"
                              color="warning"
                              :loading="loading"
                              :disabled="loading"
                              type="submit"
                            >Sign-in</v-btn>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-form>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
      </h1>  
    </div>
  </v-app>
</template>

<style scoped>
#app {
  background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.719)), url("../assets/bg.svg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>

<script>
export default {
  created(){
        sessionStorage.getItem('isAuthenticated') ? this.$router.push('/dashboard') : this.$router.push('/', () => {})
  },
  data() {
    return {
      username: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    login() {
      this.loading = true
      this.$store.dispatch({
        type:"auth/login",
        payload:{
          username : this.username,
          password : this.password
        }
      }).then(() => {
          this.loading = false
      })
    },
  },
};
</script>
