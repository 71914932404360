import store from '@/store'
import api from '../api'
// import router from '@/router'

const statuses = {
    namespaced:true,
    store,
    state(){
        return{
            datas:[]
        }
    },
    mutations:{
        datas(state,payload){
            state.datas = payload
        }
    },
    actions:{
        datas(context,payload){
            api.get(`/statuses`)
            .then((res) => {
                context.commit('datas', res.data)
            }).catch((err) => {
                console.log(err)
            })
        }
    },
    getters:{
        datas(state){
            return state.datas
        }
    }
}

export default statuses