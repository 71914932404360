import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import notification from './modules/notification'
import employees from './modules/employees'
import positions from './modules/positions'
import projects from './modules/projects'
import statuses from './modules/statuses'

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    auth,
    notification,
    employees,
    positions,
    projects,
    statuses
  }
})
