import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/login.vue'
import dashboard from '@/views/dashboard.vue'
import reports from '@/views/reports.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/reports',
    name: 'reports',
    component: reports,
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    
    // Check if the user is authenticated (customize this based on your authentication mechanism)
    if (sessionStorage.getItem('isAuthenticated') !== 'true') {
      // If not authenticated, redirect to the login page
      next('/');
    } else {
      // If authenticated, continue with the navigation
      next();
    }
  } else {
    // If the route does not require authentication, continue with the navigation
    next();
  }
});

export default router
