import store from '@/store'
import router from '@/router'
import api from '../api'

const positions
 = {
    namespaced:true,
    store,
    state(){
        return{
            datas:[]
        }
    },
    mutations:{
        datas(state,payload){
            state.datas = payload
            console.log(payload)
        }
    },
    actions:{
        async datas(context,payload){
            await api.get('/positions')
            .then((res) => {
                context.commit('datas',res.data)
            }).catch((err) => {
                console.log(err)
            })
        }
    },
    getters:{
        datas(state){
            return state.datas
        }
    }
}

export default positions
