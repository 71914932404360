import axios from "axios";
// import router from '@/router'
// let cookie = document.cookie;

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
    }
  });

api.interceptors.request.use(
(config) => {
    // config.withCredentials = true;

    const token = sessionStorage.getItem("authToken");

    // Check if a token exists and add it as an authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
},
(error) => {
    console.log(error)
    if (error.response.status === 401) {
        return Promise.resolve(error.response);
    
    }
    return Promise.reject(error);
}
);
  

// const api = axios.create({
//     baseURL: process.env.VUE_APP_API_URL,
//     headers: {
//         'accept' : 'application/json',
//         'Content-Type': 'application/json',
//         // 'authorization': `Bearer ${cookie.split("=")[1]}`,
//         'Access-Control-Allow-Credentials':true,
//         'Access-Control-Allow-Origin': "*",
//         'Origin': 'http://localhost:8080',
//     }
// });

// // alert(process.env.VUE_APP_API_URL);

// // // Alter defaults after instance has been created
// // default header
// // api.defaults.headers.common['Authorization'] = cookie && cookie.split("=")[1];
// // console.log(`${cookie && cookie.split("=")[1]}`)

// api.interceptors.request.use(function (config) {
//     const _cookie = document.cookie
//     // Do something before request is sent
//     config.withCredentials = true;
//     // console.log(_cookie)
//     config.headers.authorization =  `Bearer ${_cookie.split("=")[1]}`;
//     return config;
// }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
// });


export default api;