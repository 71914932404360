<template>
    <v-container fluid>
        <v-card elevation="0" class="mb-10">
            <v-card-title>Master List</v-card-title>
            <v-card-subtitle>
                {{ projectDatas.data[0].description}}
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-subtitle>
                Postions 
                <v-btn  right color="error" text @click="resetPostionFilter">
                    <v-icon>mdi-reload</v-icon>
                </v-btn>
            </v-card-subtitle>
            <v-row>
                <v-col >
                    <v-row class="mt-4 -mb-16" >
                        <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="positions in positionDatas.data" :key="positions.id">
                            <v-checkbox 
                                :label="positions.description"
                                :value="positions.id"
                                v-model="selectedPositions"
                                class="-mt-10"
                                :disabled="radios === 'Active'  ? false : !positionHasInactiveMember(positions.id) && radios === 'All'  ? false : !positionHasInactiveMember(positions.id)"
                            >
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <!-- <v-card-actions v-for="status in statusesDatas.data" :key="status.id">
                        <v-btn :color="status.description == 'Active' ? 'success' : 'error'  ">{{status.description}}</v-btn>
                    </v-card-actions> -->
                    <v-card-subtitle class="-mt-12">Status</v-card-subtitle>
                    <v-radio-group v-model="radios" >
                        
                        <v-radio v-for="status in statusesDatas.data" :key="status.id" :value="status.description" :color="status.description == 'Active' ? 'success' : 'error' ">
                            <template v-slot:label>
                                <div>{{status.description}}</div>
                            </template>
                        </v-radio>
                        <v-radio value="All" color="primary">
                            <template  v-slot:label>
                                <div>All</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-card class="-mt-4 mb-2">
                        <v-card-subtitle class="-m-2">
                            Overall: <span class="text-lg font-medium ml-2">{{datas.data.length}}</span>
                         </v-card-subtitle>
                    </v-card>
                    <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-card>
                                <v-card-subtitle class="-m-2">
                                    Active: <span class="text-lg font-medium ml-2">{{activeCount}}</span>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                          
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-card>
                                 <v-card-subtitle class="-m-2">
                                    Inactive: <span class="text-lg font-medium ml-2">{{inactiveCount}}</span>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>
                    
                </v-col>
            </v-row>
                
        </v-card>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" @click="add">
                Add
            </v-btn>
        </v-card-actions>
        
        <v-divider></v-divider>
        <v-card elevation="0" class="">
            <custom-table
                :headers="headers"
                :datas="filteredData == '' ? datas.data : filteredData "
                :buttons="buttons"
                searchLabel="Search"
            />
        </v-card>
        
        <!-- {{datas.data}} -->
        <v-row justify="center">
            <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            >
            <v-card>
                <v-form @submit.prevent="save">
                    <v-card-title class="-mt-4 -mb-8">
                        <span>Add Employee</span>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-col
                            cols="12"
                            sm="6"
                        >
                            <v-autocomplete
                                :items="statusesDatas.data"
                                return-object
                                label="Status"
                                item-text="description"
                                item-value="id"
                                v-model="statusId"
                            ></v-autocomplete>
                        </v-col>
                    </v-card-title>
                    <v-divider></v-divider>
                        <v-card-text>
                        <v-container>
                            
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    label="Full Name"
                                    required
                                    v-model="name"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-autocomplete
                                    :items="positionDatas.data"
                                    return-object
                                    label="Position"
                                    item-text="description"
                                    item-value="id"
                                    v-model="positionId"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-menu
                                    ref="menu1"
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="dateFormatted"
                                        label="Date Hired"
                                        hint="MM/DD/YYYY format"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateHired"
                                        no-title
                                        @input="menu1 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            
                            
                            </v-row>
                        </v-container>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                            type="submit"
                            v-if="!isEditing"
                        >
                            Save
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                            type="submit"
                            v-if="isEditing"
                        >
                            update
                        </v-btn>
                        </v-card-actions>
                </v-form>
            </v-card>
            </v-dialog>

            <v-dialog v-model="deletionDialog" max-width="500px">
                <v-card>
                <v-card-title class="headline">Confirm Deletion</v-card-title>
                <v-card-text>
                    Are you sure you want to <span class="font-semibold text-blue-700">{{selectedName}}</span> ?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="deleteConfirmed">Yes</v-btn>
                    <v-btn color="error" @click="deletionDialog = false">No</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import customTable from '@/components/customTable.vue'
import { mapGetters } from 'vuex'
export default {
    mounted(){
        this.$store.dispatch({
            type:"employees/datas"
        })

        this.$store.dispatch({
            type:"positions/datas"
        })

        this.$store.dispatch({
            type:"projects/datas"
        })

        this.$store.dispatch({
            type:"statuses/datas"
        })
    },
    data(){
        return{
            selectedPositions:[],
            radios:"Active",
            dialog: false, 
            menu1: false,
            projectId: parseInt(sessionStorage.getItem('projectId')),
            statusId : {id : 1},
            name:"",
            positionId : [],
            dateHired: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            deletionDialog : false,
            selectedEmployeeId : "",
            selectedEmployeeIndex : "",
            selectedName: "",
            isEditing: false,
            headers: [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: 'Project',
                    align: 'start',
                    sortable: true,
                    value: 'projectId',
                },
                {
                    text: 'Position',
                    align: 'start',
                    sortable: true,
                    value: 'positionId',
                },
                {
                    text: 'Date Hired',
                    align: 'start',
                    sortable: true,
                    value: 'dateHired',
                },
                {
                    text: 'Status',
                    align: 'start',
                    sortable: true,
                    value: 'statusId',
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },
                    ],
            buttons: [
                {
                    icon: "mdi-file-edit-outline",
                    color: "primary",
                    tooltip: "Modify",
                    position: "left",
                    action: (item) => {
                    this.triggerModifyDialog(item);
                    },
                },
                {
                    icon: "mdi-trash-can-outline",
                    color: "error",
                    tooltip: "Delete Account",
                    position: "left",
                    action: (item) => {
                    this.triggerDeleteDialog(item);
                    },
                },
            ],
        }
    },
    components:{    
        customTable
    },
    computed:{
        ...mapGetters({
            datas : "employees/datas",
            positionDatas : "positions/datas",
            statusesDatas : "statuses/datas",
            projectDatas : "projects/datas"
        }),
       filteredData() {
            return this.datas && this.datas.data && this.datas.data.filter((item) => {
                // Check if the item's positionId is in the selectedPositions array
                const positionFilter = this.selectedPositions.length === 0 || this.selectedPositions.includes(item.positionId);

                // Check if the item's status matches the selected status
                 const statusFilter = this.radios === 'All' || (this.radios === 'Active' ? item.statusId === 1 : item.statusId === 2);

                // Return true only if both positionFilter and statusFilter are true
                
                return positionFilter && statusFilter
            });
        },

        activeCount() {
            return this.datas.data && this.datas.data.filter((item) => item.statusId === 1).length;
        },

        inactiveCount() {
            return this.datas.data && this.datas.data.filter((item) => item.statusId === 2).length;
        },
        computedDateFormatted () {
            return this.formatDate(this.dateHired)
        },
        

    },
    watch:{
        radios(value){
            value === 'Inactive' ?  this.selectedPositions = [] : ''
        },
        dateHired (val) {
            this.dateFormatted = this.formatDate(this.dateHired)
        },
    },
    methods:{
        resetPostionFilter(){
            this.selectedPositions = []

            this.radios = 'Active'
        },
        positionHasInactiveMember(positionId) {
        // Check if any member has the selected position and "Inactive" status
            return this.datas.data.some((item) => {
                return item.positionId === positionId && item.statusId === 2;
            });
        },
        add(){
            this.dialog = true
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        save() {
            if (!this.isEditing) {
                this.$store.dispatch({
                type: "employees/store",
                payload: {
                    "statusId": this.statusId.id, // Convert to integer if it's not
                    "name": this.name,
                    "projectId": this.projectId,
                    "positionId": this.positionId.id, // Convert to integer if it's not
                    "dateHired": this.dateHired,
                },
                });
            } else {
                this.$store.dispatch({
                type: "employees/update",
                payload: {
                    request: {
                    "statusId": this.statusId.id, // Convert to integer if it's not
                    "name": this.name,
                    "projectId": this.projectId,
                    "positionId": this.positionId.id === undefined ? this.positionId : this.positionId.id, // Convert to integer if it's not
                    "dateHired": this.dateHired,
                    },
                    index: this.selectedEmployeeIndex,
                    id: this.selectedEmployeeId,
                },
                });
          
            }
        },
        triggerModifyDialog(item) {
            this.selectedEmployeeId = item.id;
            this.selectedEmployeeIndex = this.datas.data.indexOf(item);
            this.isEditing = true;
            this.dialog = true;

            // Ensure statusId and positionId are objects
            
            this.statusId.id = item.statusId.id === undefined ? item.statusId : item.statusId.id ;
            this.positionId.id =  item.positionId === undefined ? item.positionId : item.positionId.id;
            this.name = item.name;
            this.projectId = item.projectId;
            this.dateHired = item.dateHired;
        },
        deleteConfirmed(){
            this.$store.dispatch({
                type: "employees/destroy",
                payload:{
                    id: this.selectedEmployeeId,
                    index : this.selectedEmployeeIndex
                }
            }).then(() => {
                this.deletionDialog = false
            })
        },
        triggerModifyDialog(item){
            this.selectedEmployeeId = item.id
            this.selectedEmployeeIndex = this.datas.data.indexOf(item)
            this.isEditing = true
            this.dialog = true

            

            this.statusId = {id : item.statusId}
            this.name = item.name
            this.positionId =item.positionId
            this.projectId = item.projectId
            this.dateHired = item.dateHired
        },
        triggerDeleteDialog(item){
            this.deletionDialog = true
            this.selectedEmployeeId = item.id
            this.selectedName = item.name
            this.selectedEmployeeIndex = this.datas.data.indexOf(item)
        },
        closeDialog() {
            this.dialog = false;

            if (this.isEditing) {
                this.statusId = "" // Set to default value as an object
                this.name = "";
                this.positionId = "" // Set to default value as an object
                this.projectId = "";
                this.dateHired = "";
                this.isEditing = false;
            }
        },
    }
}
</script>