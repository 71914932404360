<template>
    <v-container fluid>
        <v-card class="mb-10">
            <v-card-title>Reports</v-card-title>
            <v-card-subtitle>
                
                <v-row class="-mt-6"> 
                    <v-col>{{ projectDatas.data[0].description}}</v-col>
                    <v-col class="-mt-5 -mb-5">
                        <v-autocomplete
                            :items="positionDatas.data"
                            label="Filter Positions"
                            item-text="description"
                            item-value="id"
                            v-model="positionsId"
                            multiple
                            small-chips
                            dense
                        ></v-autocomplete>
                    </v-col>
                    
                    <v-col class="-mt-5 -mb-5">
                        <v-autocomplete
                            :items="statusesDatas.data"
                            label="Filter Status"
                            item-text="description"
                            item-value="id"
                            v-model="statusesId"
                            multiple
                            small-chips
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="d-flex justify-end -mt-8">
                        <v-card-actions>
                            <v-btn @click="printReport" color="primary">Print</v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
                
            </v-card-subtitle> 
    
        </v-card>

            
        <custom-table
            :headers="headers"
            :datas="filteredData"
            searchLabel="Search"
        />


    </v-container>
</template>

<script>
import customTable from '@/components/customTable.vue'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { mapGetters } from 'vuex';
export default {
   data(){
    return{
        positionsId: [],
        statusesId: [],
        headers: [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: 'Project',
                    align: 'start',
                    sortable: true,
                    value: 'projectId',
                },
                {
                    text: 'Position',
                    align: 'start',
                    sortable: true,
                    value: 'positionId',
                },
                {
                    text: 'Date Hired',
                    align: 'start',
                    sortable: true,
                    value: 'dateHired',
                },
                {
                    text: 'Status',
                    align: 'start',
                    sortable: true,
                    value: 'statusId',
                },
        ],
    }
   },
   created(){
        this.datas.data === undefined ? 
        this.$store.dispatch({
            type:"employees/datas"
        }) : ""

        this.projectDatas.data === undefined ? 
        this.$store.dispatch({
            type:"projects/datas"
        }) : ""

   
        this.$store.dispatch({
            type:"statuses/datas"
        }) 

        this.$store.dispatch({
            type:"positions/datas"
        })

   },
   components:{
    customTable,
   },
   computed:{
    ...mapGetters({
        "projectDatas" : "projects/datas",
        "datas" : "employees/datas",
        "statusesDatas" : "statuses/datas",
        "positionDatas" : "positions/datas"
    }),
    filteredData() {
            return this.datas && this.datas.data && this.datas.data.filter((item) => {
                // Check if the item's positionId is in the selected positions
                const positionFilter = this.positionsId.length === 0 || this.positionsId.includes(item.positionId);
                console.log(this.positionsId.includes(item.positionId))
                // Check if the item's status matches the selected statuses
                const statusFilter = this.statusesId.length === 0 || this.statusesId.includes(item.statusId);
                // Return true only if both positionFilter and statusFilter are true
                return positionFilter && statusFilter;
            });
        },
   },
   methods:{
    printReport() {
        const doc = new jsPDF();

        // Define the report title and location
        const reportTitle = this.projectDatas.data[0].description;
        const location = this.projectDatas.data[0].location;

        // Define the content of the report based on the filtered data
        const filteredData = this.filteredData;

        // Sort the filtered data by position
        const sortedData = this.sortByPosition(filteredData);

        // Set the initial y-position for content
        let yPosition = 20;

        // Add the report title and location to the PDF
        doc.setFontSize(16);
        doc.text(reportTitle, 105, yPosition, { align: 'center' });
        yPosition += 5;

        doc.setFontSize(12);
        doc.text(`${location}`, 105, yPosition, { align: 'center' });
        yPosition += 15;

        // Create a table for each position
        sortedData.forEach(positionData => {
            const positionName = positionData.positionName || 'Other'; // Use 'Other' if positionName is undefined
            doc.setFontSize(11);
            doc.text(`${positionName} - (${positionData.data.length})`, 14, yPosition);
            yPosition += 2;

            const table = {
            startY: yPosition,
            head: ['Name', 'Position', 'Date Hired', 'Status'],
            body: [],
            };

            positionData.data.forEach(item => {
            table.body.push([
                item.name,
                this.getPositionDescription(item.positionId),
                item.dateHired,
                this.getStatusDescription(item.statusId),
            ]);
            });

            // Add the table to the PDF
            doc.autoTable({
            startY: table.startY,
            head: [table.head],
            body: table.body,
            theme: 'striped', // Apply striped theme for a professional look
            styles: { halign: 'center' }, // Center-align the content
            });

            // Update the y-position for the next position
            yPosition = doc.autoTable.previous.finalY + 10;
        });

        // Add the summary section
        yPosition += 10;
        doc.setFontSize(12);
        doc.text('Summary:', 14, yPosition);
        yPosition += 8;

        // Create a summary table for positions
        const summaryTable = {
            startY: yPosition,
            head: ['Position', 'Number of Employees'],
            body: [],
        };

        sortedData.forEach(positionData => {
            const positionName = positionData.positionName || 'Other';
            const count = positionData.data.length;
            summaryTable.body.push([positionName, count]);
        });

        doc.autoTable({
            startY: summaryTable.startY,
            head: [summaryTable.head],
            body: summaryTable.body,
            theme: 'striped',
            styles: { halign: 'center' },
        });

        // Save or open the PDF
        doc.save('report.pdf');
    },
    sortByPosition(data) {
        const groupedData = {};

        data.forEach(item => {
            const positionId = item.positionId || 'Other'; // Use 'Other' if positionId is undefined
            if (!groupedData[positionId]) {
            groupedData[positionId] = { positionName: this.getPositionDescription(positionId), data: [] };
            }
            groupedData[positionId].data.push(item);
        });

        // Convert the object to an array
        return Object.values(groupedData);
    },
    getPositionDescription(positionId) {
        const position = this.positionDatas.data.find(item => item.id === positionId);
        return position ? position.description : '';
    },
    getStatusDescription(statusId) {
        const status = this.statusesDatas.data.find(item => item.id === statusId);
        return status ? status.description : '';
    }
   }
};
</script>

