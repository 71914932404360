<template>
    <v-navigation-drawer
        permanent
        app width="270"
    >
        <v-list >
        <v-list-item link>
            <v-list-item-content>
            <v-list-item-title class="">
                <!-- {{userDetails && userDetails.username}} -->
                <v-avatar
                    color="primary"
                    size="46"
                    class="mr-2 ml-4 font-semibold text-white"
                >{{avatar}}
                </v-avatar>
                {{name}}
            </v-list-item-title>
            <v-list-item-subtitle>
                <!-- {{userDetails && userDetails.email}} -->
            </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        </v-list>
        <v-divider></v-divider>
      <v-list nav dense>
    <v-list-item-group v-model="model" color="primary">
        <v-list-item
            v-for="(item, i) in items"
            :key="i" 
            @click="goto(item.link)"
        >
            <v-list-item-icon>
            <v-icon >{{item.icon}}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
            <v-list-item-title>{{item.text}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>  
        </v-list-item-group>
    </v-list>


        <v-footer
            padless
            class="absolute bottom-0 w-full"
        >
            <v-list-item nav class="w-full" link @click="logout">
                <v-list-item-icon>
                <v-icon color="error">mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
        </v-footer>
    </v-navigation-drawer>
</template>

<script>

export default {
    created(){
        this.path = this.$route.path
    },
    data(){
        return{
            items: [
                {
                    icon: 'mdi-monitor-dashboard',
                    text: 'masterlist',
                    link: '/dashboard'
                },
                 {
                    icon: 'mdi-chart-timeline',
                    text: 'Reports',
                    link: '/reports'
                },
            ],
            model: 0,
            path: "",
        }
    },
    methods:{
       goto(page){
            this.$router.push(page)
            .catch(()=>{})
        },
        logout(){
            this.$store.dispatch({
                type:'auth/logout'
            })
        }
    },
    computed:{
       avatar() {
            const words = sessionStorage.getItem('user').split(' ');

            let initials = '';

            for (const word of words) {
                if (word.length > 0) {
                initials += word[0].toUpperCase(); // Convert to uppercase if needed
                }
            }

            return initials;
        },
        name() {
            return sessionStorage.getItem('user');
        },
        
    },
    watch:{
        path(value){
            console.log(value)
            value === "/dashboard" ? this.model = 0 : ""
            value === "/reports" ? this.model = 1 : ""
            // value === "/solutions" ? this.model = 2 : ""
            // value === "/manage/users" ? this.model = 3 : ""
        },
    },
    
}
</script>