<template>
  <v-card flat>
    <v-card-title class="p-0 m-0">
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="search"
            clearable
            :label="searchLabel"
            color="blue-accent-3"
            append-inner-icon="mdi-magnify"
            dense
            outlined
            class="mt-6 ml-4"
          >
            <template v-slot:append-inner>
              <v-fade-transition leave-absolute>
                <v-progress-circular
                  v-if="loading"
                  color="info"
                  indeterminate
                  size="24"
                ></v-progress-circular>

                <img
                  v-else
                  height="24"
                  width="24"
                  src="https://cdn.vuetifyjs.com/images/logos/v-alt.svg"
                  alt=""
                />
              </v-fade-transition>
            </template>

            <template v-slot:append>
              <v-menu>
                <v-card>
                  <v-card-text class="pa-6"> </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      :loading="!isLoading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="datas"
      :search="search"
      :buttons="buttons"
    >
      <!-- action global -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom v-for="(button, index) in buttons" :key="index">
          <template v-slot:activator="{ attrs }">
            <v-icon
              small
              class="mr-2"
              v-on:click="button.action(item,index)"
              :color="button.color"
              v-bind="attrs"
              button.position
            >
              {{ button.icon }}
            </v-icon>
          </template>
        </v-tooltip>
      </template>
      <!-- action global -->

      <!-- Employee name -->
      <template v-slot:[`item.name`]="{ item }">
        <v-btn 
          @click="selectedData(item)"
          color="primary" text>{{item.name}}
        </v-btn>
      </template>
      <!-- Employee name -->

      <!-- employee dateHired -->
      <template v-slot:[`item.dateHired`]="{ item }">
          {{ formatDate(item.dateHired) }}
      </template> 
      <!-- employee dateHired -->

      <!-- employee positions -->
      <template v-slot:[`item.positionId`]="{ item }">
          <!-- {{ formatDate(item.dateHired) }} -->
          <!-- {{this.items.find(item => item.id === itemIdToFind)}} -->
          {{positions && positions.data && positions.data.find(position => position.id === item.positionId).description}}
      </template> 
      <!-- employee positions -->

      <!-- employee project -->
        <template v-slot:[`item.projectId`]="{ item }">
            {{projects && projects.data && projects.data.find(project => project.id === item.projectId).description}}
        </template> 
      <!-- employee project -->

      <!-- employee statuses -->
        <template v-slot:[`item.statusId`]="{ item }">
            <v-chip small outlined 
              :color="item.statusId == 1? 'success' : 'error'"
            >
              {{statuses && statuses.data && statuses.data.find(status => status.id === item.statusId).description}}
            </v-chip>
        </template> 
      <!-- employee statuses -->
    </v-data-table>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: [
    "headers",
    "datas",
    "searchLabel",
    "buttons",
  ],
  name: "",
  mounted() {
    // console.log(this.headers.title);
  },
  data() {
    return {
      search: "",
      isLoading: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      loading: false,
    };
  },
  methods: {
    selectedData(item){
      console.log(item.id)
    },
    formatDate(isoDate) {
      const date = new Date(isoDate);
      // Format the date as needed
      const options = { year: 'numeric', month: 'long', day: 'numeric'};
      return date.toLocaleDateString('en-US', options); // Adjust the locale as needed
    },
  },
  computed:{
    ...mapGetters({
      positions : "positions/datas",
      projects : "projects/datas",
      statuses : "statuses/datas"
    })
  }
};
</script>
