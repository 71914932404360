import store from '@/store'
// import router from '@/router'
import api from '../api'

const projects = {
    namespaced:true,
    store,
    state(){
        return{
            datas:[]
        }
    },
    mutations:{
        datas(state,payload){
            state.datas = payload
        }
    },
    actions:{
        async datas(context,payload){
            api.get(`projects`)
            .then((res) => {
                context.commit('datas',res.data)
            }).catch((err) => {
                console.log(err)
            })
        }
    },
    getters:{
        datas(state){
            return state.datas
        }
    }
}

export default projects