import store from '@/store'
import router from '@/router'
import api from '../api'
import isEqual from 'lodash/isEqual';
const employees = {
    namespaced:true,
    store,
    state(){
        return{
            datas:[]
        }
    },
    mutations:{
        datas(state,payload){
            state.datas = payload
        },
        store(state,payload){
            state.datas.data.push(payload);
        },
        destroy(state,payload){ 
            state.datas.data.splice(payload, 1);
        },
        update(state, payload) {
            const index = payload.index;
            const updatedData = payload.response;

            if (index !== -1) {
            state.datas.data.splice(index, 1, updatedData);
            }
        },
    },
    actions:{
        async datas(context,payload){
            await api.get('/employees')
            .then((res) => {
                context.commit('datas', res.data)
            }).catch((err) => {
                console.log(err)
            })
        },
        async store(context,payload){
            console.log(payload.payload)
            await api.post('/employees', payload.payload)
            .then((res) => {
                console.log(res.data)
                context.commit("store",res.data)
                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content:`Success! ${res.data.name} has been added.`
                    }
                })
            })
            .catch((err) => {
                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"error",
                        content: err.response.data.message
                    }
                })
                
            })
        },
        async destroy(context,payload){
            api.delete(`/employees/${payload.payload.id}`)
            .then((res) => {
                context.commit('destroy',payload.payload.index)
                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content: res.data
                    }
                })
            })
            .catch((err) => {
                console.log(err)
            })
        },
        async update(context,payload){

            const originalData = {
                'statusId' : context.state.datas.data[payload.payload.index].statusId,
                'name' : context.state.datas.data[payload.payload.index].name,
                'projectId' : context.state.datas.data[payload.payload.index].projectId,
                'dateHired' : context.state.datas.data[payload.payload.index].dateHired,
                'positionId' : context.state.datas.data[payload.payload.index].positionId
            }; // Get the original data
            const updatedData = {
                'statusId' : payload.payload.request.statusId,
                'name' : payload.payload.request.name,
                'projectId' : payload.payload.request.projectId,
                'dateHired' : payload.payload.request.dateHired,
                'positionId' : payload.payload.request.positionId,
            }; // The updated data
        
            const hasDataChanged = !isEqual(originalData, updatedData);

            if (hasDataChanged){
                api.put(`employees/${payload.payload.id}`, payload.payload.request)
                .then((res) => {
                    context.commit('update',{
                        response : res.data,
                        id : payload.payload.id,
                        index : payload.payload.index
                    })

                    this.dispatch({
                        type:"notification/snackbar",
                        data:{
                            type:"success",
                            content: `${res.data.name} information updated successfully.`
                        }
                    })
                }).catch((err) => {
                    console.log(err)
                })
            }
            
            
        },
    },
    getters:{
        datas(state){
            return state.datas
        }
    }
}

export default employees