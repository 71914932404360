<template>
  <div>
    <v-app>
      <v-app-bar
        app
        color="#6499E9"
        flat
        dark
      >
        <v-toolbar-title class="font-bold">Manpower Monitoring</v-toolbar-title>

        <v-spacer></v-spacer>

      </v-app-bar>

      <sidebar v-if="isAuth"/>

      <v-main>
          <router-view/>
           <v-snackbar
              v-model="snackbar.active"
              :color="snackbar.type"
              timeout="3000"
              top
            >
              <div class="d-flex justify-space-between align-center">
                <span>
                  {{snackbar.content}}
                </span>

                <v-btn dark icon @click="snackbar.active = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-snackbar>
      </v-main>
    </v-app>
  </div>
  
</template>

<script>
import sidebar from './views/navigationDrawer/sidebar.vue'
import {mapGetters} from 'vuex'
export default {
  name: 'App',

  data: () => ({

    // snackbar: true,
  }),
  components:{
    sidebar
  },
  computed:{
    ...mapGetters({
      snackbar: "notification/snackbar",
      isAuth: "auth/isloggedIn"
    })
  },
  mounted(){
  
  }
};
</script>
